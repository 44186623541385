import { useNavigate } from '@remix-run/react';

import {
  type DtoChannel,
  type DtoProgram,
} from '@lp-lib/api-service-client/public';

import { ChannelMemberAvatar } from '../../../../src/components/Channel/ChannelMemberAvatar';
import { useChannels } from '../../../../src/components/Channel/hooks/useChannels';
import { ChannelUtils } from '../../../../src/components/Channel/utils';
import { ArrowRightIcon } from '../../../../src/components/icons/Arrows';
import { OrgUtils } from '../../../../src/components/Organization/utils';
import { type Organization } from '../../../../src/types';

function ChannelItem(props: {
  channel: DtoChannel;
  org: Organization;
  program: DtoProgram;
  onClick?: () => void;
}): JSX.Element {
  const { channel, org, program } = props;
  const connTypeInfo = OrgUtils.GetConnTypeInfo(org, channel.exConnectType);
  const Icon = connTypeInfo.icon;
  const creator = channel.creator;
  const navigate = useNavigate();

  const onClick = () => {
    const link = channel.programLinks.find(
      (l) => l.programId === program.id || l.programType === program.type
    );
    if (!link) return;
    navigate(`/programs/${link.id}`);
    props.onClick?.();
  };

  return (
    <div className='flex items-center gap-2 rounded-xl border border-secondary bg-modal px-4 py-5'>
      <div className='w-5 h-5 mr-3'>
        <Icon />
      </div>
      <div className='font-bold'>{ChannelUtils.ChannelName(channel, org)}</div>
      <div className='text-sms italic'>{channel.membersCount} People</div>
      <div className='ml-auto'></div>
      <div className='flex items-center justify-start'>
        <ChannelMemberAvatar
          exConnectType={channel.exConnectType}
          hideIfNoExConnection
          member={channel.creator}
        />
        <div className={`flex flex-col items-start`}>
          <div className='text-sms'>
            {!!channel.exConnectType
              ? `${creator.fullName} (Owner)`
              : 'Created by Luna Park'}
          </div>
        </div>
      </div>
      <button type='button' className='btn w-3 h-3 ml-4' onClick={onClick}>
        <ArrowRightIcon />
      </button>
    </div>
  );
}

export function ProgramDetailsUsedBy(props: {
  organization: Organization;
  program: DtoProgram;
}) {
  const { organization, program } = props;

  const { data: channels = [] } = useChannels(organization?.id || '');

  const usedChannels = channels.filter((c) =>
    c.programLinks.find(
      (l) =>
        l.status === 'active' &&
        (l.programId === program.id || l.programType === program.type)
    )
  );

  if (usedChannels.length === 0) return null;
  if (!organization) return null;
  return (
    <div className='w-[820px] flex flex-col gap-4'>
      <div className='text-sms font-bold'>Actively Used By:</div>
      <div className='flex flex-col gap-2'>
        {usedChannels.map((c) => (
          <ChannelItem
            key={c.id}
            channel={c}
            org={organization}
            program={program}
          />
        ))}
      </div>
    </div>
  );
}
